<template>
  <v-container class="login" fluid>
    <v-card class="center-card">
      <v-card-text>
        <v-form 
          v-model="formValid" 
          :disabled="processing" 
          @submit.prevent="formValid && submitLogin()"
        >
          <v-text-field 
            class="mb-6" 
            v-model="username" 
            label="登入" 
            solo flat
            :rules="[v => $validate.required(v) || '請輸入帳號']"
          ></v-text-field>
          <v-text-field 
            v-model="password" 
            type="password" 
            label="密碼" 
            solo flat
            :rules="[v => $validate.required(v) || '請輸入密碼']"
          ></v-text-field>
          <v-btn block color="accent" class="mt-3 black--text" depressed :loading="processing" :disabled="!formValid" type="submit"> 登入 </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="loginError" timeout="4000" color="error" top>
      <div class="d-flex align-center">
        <v-icon class="mr-2" color="white">
          {{ icon.mdiAlertOctagon }}
        </v-icon>
        {{ errorMsg }}
      </div>
      <template #action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="loginError = false">
          <v-icon>{{ icon.mdiClose }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mdiClose, mdiAlertOctagon } from '@mdi/js'
import { defaultPage } from '@/assets/cannotAccessList'

export default {
  name: 'Login',
  computed: {
    processing() {
      return this.$store.getters.isLoading
    }
  },
  data: () => ({
    icon: {
      mdiAlertOctagon,
      mdiClose,
    },

    formValid: false,
    loginError: false,

    errorMsg: '',

    username: '',
    password: '',
  }),

  methods: {
    async submitLogin() {
      if (this.processing) {
        return;
      }

      if (!this.$validate.DataValid(this.username) || !this.$validate.DataValid(this.password)) {
        this.errorMsg = '請填滿所有必填項目';
        this.loginError = true;
        return;
      }

      this.loginError = false;
      this.$store.dispatch('setLoading', true);

      try {
        const data = await this.$Fetcher.Login(this.username, this.password);

        if (data.user_type === 'developer') {
          const { data: schools } = await this.$Fetcher.GetSchools();
          await this.setUserData({
            id: data.id,
            user_type: data.user_type,
            last_login: new Date().getTime(),
            username: data.username,
            selected_school: this.$validate.DataValid(schools) ? schools[0].id :  null,
            schools: this.$validate.DataValid(schools) ? schools.map(el => el.id) : [],
          })
        } else {
          await this.setUserData({
            id: data.id,
            user_type: data.user_type,
            last_login: new Date().getTime(),
            username: data.username,
            selected_school: this.$validate.DataValid(data.schools) ? data.schools[0] :  null,
            schools: data.schools,
          })
        }
       
        if (this.$validate.DataValid(defaultPage[data.user_type])) {
          this.$router.replace(defaultPage[data.user_type]);
        }
      } catch(err) {
        this.$common.error(err);
        this.errorMsg = err;
        this.loginError = true;
        this.$store.dispatch('setLoading', false);
      }
      return;
    },
  },
}
</script>
